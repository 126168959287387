import React, { Fragment } from 'react'
import { Link } from 'gatsby'

import SEO from '../../../components/seo'
import Layout from '../../../components/layout'
import Servizio from '../../../components/Servizio'

import PickReturnImg from '../../../images/pick_return.jpg'

const paginaServizio = {
  titolo: 'Pick Return',
  parent: {
    nome: 'Accessori',
    linkUrl: '/servizi/servizi-accessori',
  },
  servizi: [
    {
      id: 1,
      nome: '',
      imgUrl: PickReturnImg,
      paragrafi: [
        {
          id: 1,
          testo:
            "Vuoi controllare il flusso delle tue spedizioni provenienti dall'estero e non? Grazie a Pick&Return-Service puoi facilmente richiedere il ritiro di pacchi fino a 50 kg in tutta Europa e senza limiti di peso, in tutta Italia.",
        },
        {
          id: 2,
          testo:
            "Per usufruire di questa possibilità è sufficiente compilare il modulo che ti verrà consegnato dalla tua Sede di riferimento, indicando l'indirizzo completo del ritiro e un nominativo di riferimento, il numero di telefono e gli orari del ritiro, l'esatto numero dei colli da ritirare e il peso totale.",
        },
      ],
      pesoDimensioni: null,
      vantaggi: [
        {
          id: 1,
          testo:
            'È il metodo ottimale per organizzare la presa in tutta Europa con arrivo al proprio indirizzo',
        },
        {
          id: 2,
          testo: 'Decidi quando la tua spedizione deve essere ritirata',
        },
        {
          id: 3,
          testo:
            'Riduci al minimo il trasporto, la movimentazione e i costi di stoccaggio',
        },
        {
          id: 4,
          testo:
            'Controlla lo stato della spedizione tramite il nostro track and trace',
        },
        {
          id: 5,
          testo:
            'Prenotazione del servizio tramite telefono, e-mail, Weblabeling (per maggiori informazioni contatta la tua Sede di riferimento), o eventualmente via fax',
        },
      ],
      compatibileCon: [
        {
          id: 1,
          nome: 'Parcel',
          linkUrl: '/servizi/parcel',
        },
        {
          id: 2,
          nome: 'Express',
          linkUrl: '/servizi/express',
        },
        {
          id: 3,
          nome: 'Logistics',
          linkUrl: '/servizi/logistics',
        },
      ],
      servizi: null,
    },
  ],
}

const PickReturn = () => {
  return (
    <Layout>
      <SEO
        title={paginaServizio.titolo}
        description={paginaServizio.servizi[0].paragrafi[0].testo}
      />
      <section className="py-8 px-6">
        <h1 className="text-3xl sm:text-4xl font-light text-gls-blue-900 leading-tight">
          {!!paginaServizio.parent && (
            <Link
              to={paginaServizio.parent.linkUrl}
              className="mr-2 text-lg text-gls-blue-600 hover:text-gls-blue-700"
            >
              {paginaServizio.parent.nome} &raquo;
            </Link>
          )}
          {paginaServizio.titolo}
        </h1>
      </section>
      <section className="bg-white lg:rounded-lg shadow-xl">
        {paginaServizio.servizi.map((servizio, index) => {
          return (
            <Fragment key={servizio.id}>
              <Servizio servizio={servizio} />
              {index < paginaServizio.servizi.length - 1 && <hr />}
            </Fragment>
          )
        })}
      </section>
    </Layout>
  )
}

export default PickReturn
